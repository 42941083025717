import React, { useState } from "react"
import { graphql, Link } from 'gatsby'
// https://github.com/angeloashmore/gatsby-source-prismic#Image-processing
import Img from "gatsby-image"

import Banner from "../components/banner"
import Filter from '../components/filter'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Listing from '../components/listing'
import ScrollToTop from "../components/scroll"
import SearchOptions from "../components/searchOptions"
import SEO from "../components/seo"

import { prettyCategory, listingCantons, listingsHasTags } from '../utils/helpers'

/** @jsx jsx */
import { Container, Box, jsx } from 'theme-ui'

const Wrapper = ({ useContainer, children, ...props }) => {
    const WrapperComponent = useContainer ? Container : Box;

    return <WrapperComponent 
        {...props}
        sx={{
            maxWidth: useContainer ? "auto" : "1800px", // Apply maxWidth only if Box
            margin: "0 auto", // Center the Box if it has a max width
            ...props.sx // Allow custom sx props to override defaults if needed
        }}
    >
        {children}
    </WrapperComponent>
}

const AllListings = (props) => {
    const listings = props.data.allPrismicEntreprise.edges.map( ({node: listing}) => listing )
    const category = props.pageContext.category
    const allSiteTags = props.pageContext.tags
    const geoZones = props.data.allPrismicGeozone.edges.map( ({ node }) => node )
    const hideFilters = props.data.prismicCategory.data.hide_filters 
    const htmlSeoText = props.data.prismicCategory.data.seo_text.html
    const metaTags = props.data.allPrismicTag.edges.map( ({node: metaTag}) => metaTag )
    const hero_image = props.data.prismicCategory.data.hero_image.fluid
    const totalCount = props.data.allPrismicEntreprise.totalCount

    // console.log("HIDE", hideFilters)
    // console.log("TAGS", allSiteTags)

    const [canton, setCanton] = useState(null)
    const [tags, setTags] = useState(new Set())
    const [searchQuery, setSearchQuery] = useState("")
    const [layout, setLayout] = useState("single") // Can be "single", or "grid"

    // if canton is null then return all listings
    // if canton is set then return filter in only listings that have 
    var filteredListings = listings.filter( listing => {
        if ( ( canton === null ) || listingCantons(listing).includes(canton) ) return listing
        return null
    })

    // Then filter by tags (remember it's a Set) too, change it to array first
    // For each selected tag, check that it is included in the listing's tags
    filteredListings = filteredListings.filter( listing => {
        if ( tags.size === 0 ) return listing
        if ( listingsHasTags(listing, tags) ) return listing
        return null
    })

    // Then filter with the search bar
    filteredListings = filteredListings.filter((listing) => {
        const searchText = searchQuery.toLowerCase()

        // Search for text inside of 3 places (title, description and tags)
        const matchesTitle = listing.data.record.text.toLowerCase().includes(searchText)
        const matchesDescription = listing.data.pitch.text.toLowerCase().includes(searchText)
        const matchesTags = listing.tags.some(tag => tag.toLowerCase().includes(searchText))
        
        return matchesTitle || matchesDescription || matchesTags
    })

    // provide Slug to Canton Name equivalence
    var zonesObject = {}
    geoZones.forEach( zone => zonesObject[zone.uid] = zone.data.name )

    var resultsNumber
    if ( canton || tags.size !== 0 || searchQuery ) {
        resultsNumber = filteredListings.filter( listing => listing !== null ).length
    } else {
        resultsNumber = totalCount
    }

    // useEffect(() => {
    //     console.log("CHANGE IN TAGS", tags)
    // }, [tags])

    return (
        <Layout>
            <SEO />

            <Container>
                {hero_image && 
                    <Img 
                        fluid={hero_image} 
                        sx={{
                            mt: 5,
                            borderRadius: 1,
                        }}
                    />
                }

                <Hero 
                    resultsNumber={resultsNumber}
                    category={category}
                    canton={canton && zonesObject[canton]}
                />

                <Filter 
                    geoZones={geoZones}
                    allTags={allSiteTags}
                    canton={canton}
                    tags={tags}
                    setCanton={setCanton}
                    setTags={setTags}
                    hideFilters={hideFilters}
                />

                <SearchOptions 
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    setLayout={setLayout}
                    layout={layout}
                />

            </Container>

            <Wrapper
                useContainer={layout === "single"}
            >
                <div
                    sx={layout === "single" ? {
                        display:  "flex",
                        flexDirection: "column",
                        gap: 3,
                    } : {
                        flexDirection: "row",
                        display: "grid",
                        gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"],
                        gap: 3,
                    }}
                >            
                    {filteredListings.map( (listing, index) => {
                        let row = (
                            <React.Fragment key={listing.id}>
                                <Listing 
                                    key={listing.id}
                                    uid={listing.uid}
                                    tags={listing.tags.filter( tag => tag !== category )}
                                    data={listing.data}
                                    prettyCategory={prettyCategory(category)}
                                />
                                {index > 3 && (index === 4 || index === 14 || index === 34) && <Banner position={index + 2} />}
                            </React.Fragment>
                        )

                        return row
                    }
                    )}
                </div>
            </Wrapper>

            {htmlSeoText && <Container>
                <div sx={{mt: 5}}>
                    <div dangerouslySetInnerHTML={{__html: htmlSeoText}}/>
                </div>
            </Container>}
            

            {metaTags && <Container>
                {metaTags.map( tag => (
                    <Link 
                        to={`/${tag.uid}`} 
                        sx={{display: "block"}}
                        key={tag.uid}
                    >
                            {tag.data.name} en Suisse romande
                    </Link>
                ))}
            </Container>}

            <ScrollToTop showAfterHeight={800} />
        </Layout>
    )
}

export default AllListings

// Il faudra trouver un autre moyen de ranker si les entreprises achètent du boost.
// sort: {fields: data___rank, order: ASC}

// Should we limit ?
// limit: 66

export const AllListingsQuery = graphql`
    query AllListingsQuery($category: String!) {
        allPrismicEntreprise(
            filter: {data: {categories: {elemMatch: {category: {uid: {eq: $category }}}}, draft: {ne: true}}},
            sort: {order: DESC, fields: data___guest},
            ) {
            edges {
                node {
                    id
                    uid
                    ...ListingFragment
                }
            }
            totalCount
        }
        allPrismicGeozone {
            edges {
              node {
                uid
                data {
                  name
                }
              }
            }
        }
        prismicCategory(uid: {eq: $category}) {
            uid
            data {
              hide_filters
              hero_image {
                fluid {
                    ...GatsbyPrismicImageFluid
                }
              }
              seo_text {
                html
              }
            }
        }
        allPrismicTag(filter: {data: {categories: {uid: {eq: $category}}}}) {
            edges {
              node {
                uid
                data {
                    name
                }
              }
            }
        }
    }
`